import React, { useEffect } from 'react';
import { useLanguage } from '../context/LanguageContext'; 
import logo from '../assets/images/TranslationLogo.png'; 
import titleImage from '../assets/images/ImageTitle2.png'; 

const HeaderContent = () => {
  const { language, setLanguage } = useLanguage(); 

  // Function to handle changes in language selection
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage); 
    localStorage.setItem('selectedLanguage', selectedLanguage); // Save selected language in local storage
  };

  useEffect(() => {
    // Get selected language from local storage when component mounts
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]); 

  return (
    <div className="header-content" style={{ display: 'flex', alignItems: 'center', backgroundColor: 'rgb(204, 232, 254)', justifyContent: 'space-between' }}>
      {/* Logo with left margin */}
      <img src={logo} alt="Logo" style={{ marginLeft: '200px', width: '150px', height: '150px', verticalAlign: 'middle' }} />

      <img src={titleImage} alt="Title" style={{ width: '500px', height: '100px', verticalAlign: 'middle' }} />

      {/* Language Selector Section */}
      <div style={{ marginRight: '200px' }}>
        <label htmlFor="language-select" style={{ marginRight: '10px' }}>Language:</label> {/* Language label */}
        <select id="language-select" value={language} onChange={handleLanguageChange}>
          <option value="PT">Portuguese</option>
          <option value="ES">Spanish</option>
          <option value="IT">Italian</option>
        </select>
      </div>
    </div>
  );
};

export default HeaderContent;
