import React from 'react';
import { useLanguage } from '../context/LanguageContext';

const InReview = ({ 
  showAliasPTFilter, 
  showAliasESFilter, 
  showAliasITFilter, 
  onToggleAliasPT, 
  onToggleAliasES,
  onToggleAliasIT 
}) => {
  const { language } = useLanguage();

  return (
    <div className="in-review-section">
      <label>
        Not in Review:
        {language === 'PT' && (
          <div>
            <input type="checkbox" id="alias-pt" checked={showAliasPTFilter} onChange={onToggleAliasPT} />
          </div>
        )}
        {language === 'ES' && (
          <div>
            <input type="checkbox" id="alias-es" checked={showAliasESFilter} onChange={onToggleAliasES} />
          </div>
        )}
        {language === 'IT' && (
          <div>
            <input type="checkbox" id="alias-it" checked={showAliasITFilter} onChange={onToggleAliasIT} />
          </div>
        )}
      </label>
    </div>
  );
};

export default InReview;
