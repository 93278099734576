import React, { useState, useEffect } from 'react';
import FiltersContainer from '../components/FiltersContainer';
import TranslationList from '../components/TranslationList';
import HeaderContent from '../components/HeaderContent';
import { fetchTranslationData } from '../services/translationApi';
import './HomePage.css';

const HomePage = () => {
  // State variables
  const [translationItems, setTranslationItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editableRowAliasPT, setEditableRowAliasPT] = useState(null);
  const [editableRowAliasES, setEditableRowAliasES] = useState(null);
  const [editableRowAliasIT, setEditableRowAliasIT] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedSortOption, setSelectedSortOption] = useState('DatePublished');
  const [selectedSortOrder, setSelectedSortOrder] = useState('descending');
  const [selectedYear, setSelectedYear] = useState('All'); 
  const [noData, setNoData] = useState(false); 
  const [showAliasPTFilter, setShowAliasPTFilter] = useState(false);
  const [showAliasESFilter, setShowAliasESFilter] = useState(false);
  const [showAliasITFilter, setShowAliasITFilter] = useState(false);

  useEffect(() => {
    // Load filter state from localStorage when component mounts
    const savedFilters = localStorage.getItem('filterState');
    if (savedFilters) {
      const filterState = JSON.parse(savedFilters);
      setSelectedCategory(filterState.selectedCategory);
      setSelectedSortOption(filterState.selectedSortOption);
      setSelectedSortOrder(filterState.selectedSortOrder);
      setSelectedYear(filterState.selectedYear);
      setShowAliasPTFilter(filterState.showAliasPTFilter);
      setShowAliasESFilter(filterState.showAliasESFilter);
      setShowAliasITFilter(filterState.showAliasITFilter);
    } else {
      saveLocalStorageFilters({ 
        selectedCategory: selectedCategory, 
        selectedSortOption: selectedSortOption, 
        selectedSortOrder: selectedSortOrder, 
        selectedYear: selectedYear, 
        showAliasPTFilter: showAliasPTFilter, 
        showAliasESFilter: showAliasESFilter,
        showAliasITFilter: showAliasITFilter 
      });
    }
    // Fetch data from your API endpoint
    fetchTranslationData()
      .then((data) => {
        if (data && data.length > 0) {
          setTranslationItems(data);
        } else {
          console.error('No data returned from the API.');
          setNoData(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setNoData(true);
      })
      .finally(() => setLoading(false));
  }, [selectedCategory, selectedSortOption, selectedSortOrder, selectedYear, showAliasESFilter, showAliasPTFilter, showAliasITFilter]);

  const saveLocalStorageFilters = (filters) => {
    localStorage.setItem('filterState', JSON.stringify(filters));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    saveLocalStorageFilters({ 
      selectedCategory: category, 
      selectedSortOption, 
      selectedSortOrder, 
      selectedYear, 
      showAliasPTFilter, 
      showAliasESFilter,
      showAliasITFilter 
    });
  };

  const handleSortChange = (sortOption) => {
    setSelectedSortOption(sortOption);
    saveLocalStorageFilters({ 
      selectedCategory, 
      selectedSortOption: sortOption, 
      selectedSortOrder, 
      selectedYear, 
      showAliasPTFilter, 
      showAliasESFilter,
      showAliasITFilter 
    });
  };

  const handleSortOrderChange = (sortOrder) => {
    setSelectedSortOrder(sortOrder);
    saveLocalStorageFilters({ 
      selectedCategory, 
      selectedSortOption, 
      selectedSortOrder: sortOrder, 
      selectedYear, 
      showAliasPTFilter, 
      showAliasESFilter,
      showAliasITFilter 
    });
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    saveLocalStorageFilters({ 
      selectedCategory, 
      selectedSortOption, 
      selectedSortOrder, 
      selectedYear: year, 
      showAliasPTFilter, 
      showAliasESFilter,
      showAliasITFilter 
    });
  };

  const handleResetFilters = () => {
    setSelectedCategory('All');
    setSelectedSortOption('DatePublished');
    setSelectedSortOrder('descending');
    setSelectedYear('All');
    setShowAliasPTFilter(false);
    setShowAliasESFilter(false);
    setShowAliasITFilter(false);
    saveLocalStorageFilters({ 
      selectedCategory: "All", 
      selectedSortOption: "DatePublished", 
      selectedSortOrder: "descending", 
      selectedYear: "All", 
      showAliasPTFilter: false, 
      showAliasESFilter: false,
      showAliasITFilter: false 
    });
  };

  const handleToggleAliasPTFilter = () => {
    setShowAliasPTFilter(!showAliasPTFilter);
    saveLocalStorageFilters({ 
      selectedCategory, 
      selectedSortOption, 
      selectedSortOrder, 
      selectedYear, 
      showAliasPTFilter: !showAliasPTFilter, 
      showAliasESFilter,
      showAliasITFilter 
    });
  };

  const handleToggleAliasESFilter = () => {
    setShowAliasESFilter(!showAliasESFilter);
    saveLocalStorageFilters({ 
      selectedCategory, 
      selectedSortOption, 
      selectedSortOrder, 
      selectedYear, 
      showAliasPTFilter, 
      showAliasESFilter: !showAliasESFilter,
      showAliasITFilter 
    });
  };

  const handleToggleAliasITFilter = () => {
    setShowAliasITFilter(!showAliasITFilter);
    saveLocalStorageFilters({ 
      selectedCategory, 
      selectedSortOption, 
      selectedSortOrder, 
      selectedYear, 
      showAliasPTFilter, 
      showAliasESFilter,
      showAliasITFilter: !showAliasITFilter 
    });
  };

  const updateTranslationItems = (updatedItems) => {
    setTranslationItems(updatedItems);
  };

  // Apply filters and sorting to the translation items
  const filteredItems = translationItems
    .filter((item) => selectedCategory === 'All' || item.category === selectedCategory)
    .filter((item) => {
      if (!showAliasPTFilter) {
        return true;
      } else {
        if (item['alias-pt'] == null || !item['alias-pt'] === ' ') return true;
      }
      return false;
    })
    .filter((item) => {
      if (!showAliasESFilter) {
        return true;
      } else {
        if (item['alias-es'] == null || !item['alias-es'] === ' ') return true;
      }
      return false;
    })
    .filter((item) => {
      if (!showAliasITFilter) {
        return true;
      } else {
        if (item['alias-it'] == null || !item['alias-it'] === ' ') return true;
      }
      return false;
    })
    .filter((item) => {
      if (selectedYear === 'All') {
        return true;
      }
      const itemYear = new Date(item.datePublished).getFullYear();
      return itemYear.toString() === selectedYear;
    })
    .sort((a, b) => {
      switch (selectedSortOption) {
        case 'DatePublished':
          const dateA = new Date(a.datePublished);
          const dateB = new Date(b.datePublished);
          return selectedSortOrder === 'ascending' ? dateA - dateB : dateB - dateA;
        case 'Category':
          return selectedSortOrder === 'ascending' ? a.category.localeCompare(b.category) : b.category.localeCompare(a.category);
        case 'ReviewedBy':
          const aliasA = a.aliasPT || '';
          const aliasB = b.aliasPT || '';
          return selectedSortOrder === 'ascending' ? aliasA.localeCompare(aliasB) : aliasB.localeCompare(aliasA);
        case 'ReviewedByES':
          const aliasESA = a.aliasES || '';
          const aliasESB = b.aliasES || '';
          return selectedSortOrder === 'ascending' ? aliasESA.localeCompare(aliasESB) : aliasESB.localeCompare(aliasESA);
        case 'ReviewedByIT':
          const aliasITA = a.aliasIT || '';
          const aliasITB = b.aliasIT || '';
          return selectedSortOrder === 'ascending' ? aliasITA.localeCompare(aliasITB) : aliasITB.localeCompare(aliasITA);
        default:
          return 0;
      }
    });

  return (
    <>
      <HeaderContent />
      <FiltersContainer
        selectedCategory={selectedCategory}
        selectedSortOption={selectedSortOption}
        selectedSortOrder={selectedSortOrder}
        selectedYear={selectedYear} 
        onCategoryChange={handleCategoryChange}
        onSortChange={handleSortChange}
        onSortOrderChange={handleSortOrderChange}
        onYearChange={handleYearChange}
        onResetFilters={handleResetFilters}
        onToggleReviewFilterPt={handleToggleAliasPTFilter}
        onToggleReviewFilterEs={handleToggleAliasESFilter}
        onToggleReviewFilterIt={handleToggleAliasITFilter}
        showAliasPTFilter={showAliasPTFilter}
        showAliasESFilter={showAliasESFilter}
        showAliasITFilter={showAliasITFilter}
      />
      <div className="table-container">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {noData ? (
              <p>No data returned from the API.</p>
            ) : (
              <TranslationList
                items={filteredItems}
                editableRowAliasPT={editableRowAliasPT}
                setEditableRowAliasPT={setEditableRowAliasPT}
                editableRowAliasES={editableRowAliasES}
                setEditableRowAliasES={setEditableRowAliasES}
                editableRowAliasIT={editableRowAliasIT}
                setEditableRowAliasIT={setEditableRowAliasIT}
                updateTranslationItems={updateTranslationItems}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HomePage;
