import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

export const LANGUAGES = {
    PT: 'Portuguese',
    ES: 'Spanish',
    IT: 'Italian'
};

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('PT'); // Default to Portuguese

    return (
        <LanguageContext.Provider value={{ language, setLanguage, languages: LANGUAGES }}>
            {children}
        </LanguageContext.Provider>
    );
};
